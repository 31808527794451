import { IChildStores } from '../stores/megaStore'

const RELEVANT_RESULTS_ARRAY = ['variants', 'breakpointsOrder', 'responsiveLayout', 'pinnedLayer'] as const
type SubStore = typeof RELEVANT_RESULTS_ARRAY[number]
type SubStores<TStore extends IChildStores<TStore>> = {
	[K in SubStore]: TStore
}

export const getSubStores = <TStore extends IChildStores<TStore>>(megaStore: TStore): SubStores<TStore> =>
	RELEVANT_RESULTS_ARRAY.reduce<SubStores<TStore>>((acc, key) => {
		acc[key] = megaStore.getChildStore(key)
		return acc
	}, {} as SubStores<TStore>)
